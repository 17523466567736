import React from 'react';
import { Box } from '@mui/material';

import HeroBanner from '../components/HeroBanner';

const Home = () => {

    return (
        <Box>
            <HeroBanner />
        </Box>
    );
};

export default Home;